<template>
  <div id="sidebar-menu">
    <!-- {{layoutobject}}	
          fdsfdsf{{[layoutobject.split(' ').includes('horizontal-wrapper')]}} -->
    <ul class="sidebar-links custom-scrollbar" id="myDIV"
      :style="[layoutobject.split(' ').includes('horizontal-wrapper') ? layout.settings.layout_type == 'rtl' ? { '  -right': margin + 'px' } : { 'margin-left': margin + 'px' } : { margin: '0px' }]">
      <li class="sidebar-list" style="display: block !important;">
      
        <router-link to="/" class="sidebar-link sidebar-title" @click="this.collapseSidebar();">
          <vue-feather type="home"></vue-feather>
          <span class="m-l-30">Início </span>
        </router-link>
      </li>
      <li class="sidebar-list">
        <router-link to="/pesquisa" class="sidebar-link sidebar-title" @click="this.collapseSidebar();">
          <vue-feather type="search"></vue-feather>
          <span class="m-l-30">Pesquisa</span>
        </router-link>
      </li>
      <li class="sidebar-list">
        <router-link to="/player" class="sidebar-link sidebar-title" @click="this.collapseSidebar();">
          <vue-feather type="play"></vue-feather>
          <span class="m-l-30">Playlist</span>
        </router-link>
      </li>
      <li class="sidebar-list">
        <router-link to="/karaoke" class="sidebar-link sidebar-title" @click="this.collapseSidebar();">
          <vue-feather type="mic"></vue-feather>
          <span class="m-l-30">Karaoke Machine</span>
        </router-link>
      </li>
      <!-- <li class="sidebar-list">
        <router-link to="/planos" class="sidebar-link sidebar-title" @click="this.collapseSidebar();">
          <vue-feather type="dollar-sign"></vue-feather>
          <span class="m-l-30">Planos</span>
        </router-link>
      </li> -->
      <li class="sidebar-list">
        <router-link to="/minha-conta" class="sidebar-link sidebar-title" @click="this.collapseSidebar();">
          <vue-feather type="settings"></vue-feather>
          <span class="m-l-30">Configurações</span>
        </router-link>
      </li>

      <li class="sidebar-list">
        <router-link to="/atendimento" class="sidebar-link sidebar-title" @click="this.collapseSidebar();">
          <vue-feather type="help-circle"></vue-feather>
          <span class="m-l-30">Atendimento</span>
        </router-link>
      </li>

    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { layoutClasses } from '../../constants/layout';

export default {
  name: 'Navmenu',
  data() {
    return {
      layoutobj: {}
    };
  },
  computed: {
    ...mapState({
      menuItems: state => state.menu.data,
      layout: state => state.layout.layout,
      sidebar: state => state.layout.sidebarType,
      activeoverlay: (state) => state.menu.activeoverlay,
      togglesidebar: (state) => state.menu.togglesidebar,
      width: (state) => state.menu.width,
      height: (state) => state.menu.height,
      margin: (state) => state.menu.margin,
      menuWidth: (state) => state.menu.menuWidth,
    }),
    layoutobject: {
      get: function () {
        return JSON.parse(JSON.stringify(layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout)))[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[this.layout.settings.layout];
        return this.layoutobj;
      }
    }
  },
  watch: {
    width() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      window.addEventListener('scroll', this.handleScroll);
      this.handleScroll();
      if (window.innerWidth < 992) {
        const newlayout = JSON.parse(JSON.stringify(this.layoutobject).replace('horizontal-wrapper', 'compact-wrapper'));
        document.querySelector('.page-wrapper').className = 'page-wrapper ' + newlayout;
        this.$store.state.menu.margin = 0;
      } else {
        document.querySelector('.page-wrapper').className = 'page-wrapper ' + this.layoutobject;
      }

      if ((window.innerWidth < 1199 && this.layout.settings.layout === 'Tokyo') || (window.innerWidth < 1199 && this.layout.settings.layout === 'Moscow') || (window.innerWidth < 1199 && this.layout.settings.layout === 'Rome')) {
        this.menuItems.filter(menuItem => {
          menuItem.active = false;
        });
      }
    }
  },
  created() {

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.$store.state.menu.width < 991) {
      this.layout.settings.layout = 'Dubai';
      this.margin = 0;
    }
    setTimeout(() => {
      const elmnt = document.getElementById('myDIV');
      this.$store.state.menu.menuWidth = elmnt.offsetWidth;
      this.$store.state.menu.menuWidth > window.innerWidth ?
        (this.$store.state.menu.hideRightArrow = false, this.$store.state.menu.hideLeftArrowRTL = false) :
        (this.$store.state.menu.hideRightArrow = false, this.$store.state.menu.hideLeftArrowRTL = true);
    }, 500);
    this.layoutobject = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);
    this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[this.layout.settings.layout];
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.menuItems.filter(items => {
      if (items.path === this.$route.path)
        this.$store.dispatch('menu/setActiveRoute', items);
      if (!items.children) return false;
      items.children.filter(subItems => {
        if (subItems.path === this.$route.path)
          this.$store.dispatch('menu/setActiveRoute', subItems);
        if (!subItems.children) return false;
        subItems.children.filter(subSubItems => {
          if (subSubItems.path === this.$route.path)
            this.$store.dispatch('menu/setActiveRoute', subSubItems);
        });
      });
    });


    var pageWrapper = document.querySelector('.page-wrapper');
    var sidebarWrapper = pageWrapper.querySelector('.sidebar-wrapper');
    var linksWrapper = document.querySelector('.sidebar-link');
    sidebarWrapper.addEventListener('mouseover', function () {
      pageWrapper.classList.add('sidebar-bg');
      pageWrapper.classList.remove('sidebar-hide-bg');
    });
    sidebarWrapper.addEventListener('mouseout', function () {
      pageWrapper.classList.remove('sidebar-bg');
      pageWrapper.classList.add('sidebar-hide-bg');
    });
    /* linksWrapper.addEventListener('click', () => {
      console.log('COLLAPSE');
      this.collapseSidebar();
    }); */

  },
  methods: {
    collapseSidebar() { // MODFIX
      const sidebar = document.querySelector('.sidebar-wrapper');
      if (sidebar) {
        sidebar.style.pointerEvents = 'none';
        setTimeout(() => {
          sidebar.style.pointerEvents = 'all';
        }, 1000)
      }
    },
    handleScroll() {
      if (window.scrollY > 400) {
        if (this.layoutobject.split(' ').pop() === 'material-type' || this.layoutobject.split(' ').pop() === 'advance-layout')
          document.querySelector('.sidebar-main').className = 'sidebar-main hovered';
      } else {
        if (document.getElementById('sidebar-main'))
          document.querySelector('.sidebar-main').className = 'sidebar-main';
      }
    },
    setNavActive(item) {
      this.$store.dispatch('menu/setNavActive', item);
      if (this.layoutobject.split(' ').includes('compact-sidebar') && window.innerWidth > 991) {
        if (this.menuItems.some(menuItem => menuItem.active === true)) {
          this.$store.state.menu.activeoverlay = true;
        } else {
          this.$store.state.menu.activeoverlay = false;
        }
      }
    },
    /* hidesecondmenu() {
      if (window.innerWidth < 991) {
        this.$store.state.menu.activeoverlay = false,
          this.$store.state.menu.togglesidebar = false;
        this.menuItems.filter(menuItem => {
          menuItem.active = false;
        });
      } else if (this.layoutobject.split(' ').includes('compact-sidebar')) {
        this.$store.state.menu.activeoverlay = false,
          this.menuItems.filter(menuItem => {
            menuItem.active = false;
          });
      }
    }, */
    handleResize() {
      this.$store.state.menu.width = window.innerWidth - 450;
    },
  }
};
</script>
<style>
.sidebar-links {
  height: auto !important;
}

.sidebar-wrapper {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  top: 50% !important;
  bottom: unset !important;
  transform: translateY(-50%);
  padding: 25px 0;
  background-color: rgba(12, 12, 15, .5) !important;
  box-shadow: none !important;
}

.sidebar-wrapper:hover {
  /* background-color: rgba(10, 10, 12, 1) !important; */
  background-color: rgba(0, 0, 0, 0) !important;

  /* background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)) !important;
  height: 100vh !important;
  top: 0 !important;
  bottom: 0 !important;
  border-radius: 0 !important;
  transform: translateX(-30px) translateY(-30px); */
}

.sidebar-wrapper ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dark-only .sidebar-wrapper .router-link-active span  {
  color: #ec1c24 !important;
}

.dark-only .sidebar-wrapper .router-link-active svg {
  stroke: #ec1c24 !important;
}

.dark-only .sidebar-wrapper a:hover svg {
  stroke: #ec1c24 !important;
}

.dark-only .sidebar-wrapper svg {
  stroke: #fff !important;
}


.sidebar-wrapper svg {
  width: 20px !important;
  height: 20px !important;
  margin: 0 5px !important;
}

.sidebar-wrapper a {
  font-size: 16px !important;
  line-height: 40px !important;
}
</style>