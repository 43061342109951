import { createRouter, createWebHistory } from "vue-router"
import Body from '../components/body';
import BodyPlayer from '../components/bodyplayer';
import BodyKaraoke from '../components/bodykaraoke';
import Home from '../pages/home.vue';
import Player from '../pages/player.vue';
import Karaoke from '../pages/karaoke.vue';
import Login from '../pages/login.vue';
import MyAccount from '../pages/myaccount.vue';
import Plans from '../pages/plans.vue';
import Contact from '../pages/contact.vue';
import Search from '../pages/search.vue';
import Dashboard from "@/pages/admin/dashboard.vue";

const routes = [
  {
    path: '/',
    component: Body,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
          title: "KaraokeFlix",
        },
      },
    ]
  },
  {
    path: '/pesquisa/:terms?',
    component: Body,
    children: [
      {
        path: '',
        name: 'search',
        component: Search,
        meta: {
          title: "Pesquisa | KaraokeFlix",
        },
      },
    ]
  },
  {
    path: '/minha-conta',
    component: Body,
    children: [
      {
        path: '',
        name: 'myaccount',
        component: MyAccount,
        meta: {
          title: "Minha Conta | KaraokeFlix",
        },
      },
    ]
  },
  /* {
    path: '/planos',
    component: Body,
    children: [
      {
        path: '',
        name: 'plans',
        component: Plans,
        meta: {
          title: "Planos | KaraokeFlix",
        },
      },
    ]
  }, */
  {
    path: '/atendimento',
    component: Body,
    children: [
      {
        path: '',
        name: 'contact',
        component: Contact,
        meta: {
          title: "Atendimento | KaraokeFlix",
        },
      },
    ]
  },
  {
    path: '/player',
    component: BodyPlayer,
    props: true,
    children: [
      {
        path: '',
        name: 'player',
        component: Player,
        meta: {
          title: "Player | KaraokeFlix",
        },
        props: true,
      },
    ]
  },
  {
    path: '/karaoke',
    component: BodyKaraoke,
    props: true,
    children: [
      {
        path: '',
        name: 'karaoke',
        component: Karaoke,
        meta: {
          title: "Karaoke | KaraokeFlix",
        },
        props: true,
      },
    ]
  },
  {
    path: '/login',
    component: null,
    children: [
      {
        path: "",
        name: "login",
        component: Login,
        meta: {
          title: "Login | KaraokeFlix",
        },
      }
    ],
  },
  {
    path: '/dashboard',
    component: Body,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: "Dashboard | KaraokeFlix",
        },
      },
    ]
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // Aqui você pode executar a lógica para fechar o menu expandido
  //fecharMenuExpandido();
  console.log('navegando', from, to);

  /* document.addEventListener('DOMContentLoaded', function () {

    // Obtém uma referência para o elemento por meio da classe
    const sidebar = document.querySelector('.sidebar-wrapper');

    if (sidebar) {

      sidebar.style.pointerEvents = 'none';

    }


  }); */

  // Continue a navegação
  next();
});



export default router