<template>
  <div style="background-color: #000;">
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">


          <div class="login-card">
            <div>

              <div>
                <a class="logo text-center">
                  <router-link to="/">
                    <img class="img-fluid" style="max-width: 180px;" src="../assets/images/logo/logo-simple-red-white.png"
                      alt />
                  </router-link>
                </a>
              </div>
              <div class="login-main text-dark">
                <b-card>
                  <b-card-text>


                    <div class="form-group" v-if="showMode === 'login'">


                      <form class="theme-form" @submit.prevent="login">


                        <h4 class="text-center mt-0 mb-8 font-light" style="font-weight: 700;">Login</h4>


                        <div class="form-group">
                          <input placeholder="Email" type="text" v-model="email" name="email" class="form-control" />

                        </div>
                        <div class="form-group">
                          <input placeholder="Senha" :type="passwordType" v-model="password" name="password"
                            class="form-control" />

                          <div class="show-hide" @click="showPassword">
                            <!-- <span class="show"></span> -->
                            <i class="fa fa-eye-slash"></i>
                          </div>
                        </div>


                        <div class="form-group">
                          <p class="text-center my-3"><a @click="resetPassword()" class="f-w-600 "
                              style="cursor: pointer; font-size: 12px; color: #ec1c24">Esqueceu a senha?</a>
                          </p>
                        </div>

                        <div class="form-group">

                          <div class="form-group mt-0 mb-0 w-100">
                            <button class="btn btn-light btn-block w-100 py-3 f-w-700" :disabled="loggingIn">
                              Entrar
                            </button>
                          </div>

                        </div>


                        <div class="form-group">

                          <div v-if="this.authError" class="form-group mt-3 mb-0 text-center">
                            <b-badge variant="danger">{{ this.authError }}</b-badge>
                          </div>

                        </div>




                        <div class="form-group">
                          <p class="text-center my-5">Ainda não tem uma conta? <a @click="showMode = 'register'"
                              class="f-w-600" style="cursor: pointer; font-size: 12px; color: #ec1c24">Cadastre-se!</a>
                          </p>
                        </div>




                      </form>


                    </div>


                    <div class="form-group" v-if="showMode === 'register'">


                      <form class="theme-form" @submit.prevent="register">


                        <h4 class="text-center mt-0 mb-8" style="font-weight: 700;">Cadastro</h4>


                        <div class="form-group">
                          <input placeholder="Email" type="text" v-model="registerEmail" name="registerEmail"
                            class="form-control" />

                        </div>
                        <div class="form-group">
                          <input placeholder="Senha" :type="passwordType" v-model="registerPassword"
                            name="registerPassword" class="form-control" />

                          <div class="show-hide" @click="showPassword">
                            <!-- <span class="show"></span> -->
                            <i class="fa fa-eye-slash"></i>
                          </div>
                        </div>
                        <div class="form-group">
                          <input placeholder="Confirme a senha" :type="passwordType" v-model="registerConfirmPassword"
                            name="registerConfirmPassword" class="form-control" />


                        </div>




                        <div class="form-group">

                          <div class="form-group mt-0 mb-0 w-100">
                            <button class="btn btn-secondary btn-block w-100 py-3 f-w-700" :disabled="loggingIn">
                              Cadastrar
                            </button>
                          </div>

                        </div>


                        <div class="form-group">

                          <div v-if="this.authError" class="form-group mt-3 mb-0 text-center">
                            <b-badge variant="danger">{{ this.authError }}</b-badge>
                          </div>

                        </div>




                        <div class="form-group">
                          <p class="text-center my-5">Já tem conta? <a @click="showMode = 'login'" class="f-w-600"
                              style="cursor: pointer; font-size: 12px">Faça Login!</a>
                          </p>
                        </div>




                      </form>


                    </div>


                    <div class="form-group" v-if="showMode !== 'verify'">

                      <p class="text-center my-3 login-div"> <span
                          style="background-color: #060a0d; padding: 0 10px; font-size:10px; color: #eee"> OU
                        </span>
                      </p>


                      <div class="form-group mt-0 mb-0 w-100">
                        <button class="btn btn-block w-100 py-5 f-w-700" @click="googleLogin()"
                          style="background-color: #fff; border: solid 1px #f0f0f0" :disabled="loggingIn">
                          <img class="img-fluid" style="max-width: 25px; margin-right: 10px;"
                            src="../assets/images/logo/g-logo.png" alt /> Login com Google
                        </button>
                      </div>

                    </div>



                    <div class="form-group" v-if="showMode === 'verify'">

                      <p class="text-center my-5"> <span
                          style="background-color: #fff; padding: 0 10px; font-size:12px; color: #666"> Por favor,
                          confirme o seu cadastro através da mensagem enviada para o seu email e tente novamente.
                        </span>
                      </p>

                      <p class="text-center my-5"> <span
                          style="background-color: #fff; padding: 0 10px; font-size:12px; color: #666"> Não recebeu o
                          email de confirmação?
                        </span>
                      </p>



                      <div class="form-group mt-0 mb-0 w-100">
                        <button class="btn btn-block btn-secondary w-100 py-3 f-w-700" @click="sendVerification(user)"
                          style="" :disabled="loggingIn">
                          Solicitar email de confirmação
                        </button>
                      </div>

                      <div class="form-group">
                        <p class="text-center mt-5 mb-0"><a @click="showMode = 'login'" class="f-w-600"
                            style="cursor: pointer; font-size: 12px">Login</a>
                        </p>
                      </div>

                    </div>

                  </b-card-text>
                </b-card>
              </div>

              <div class="my-5">
                <p class="text-center">
                  <router-link to="/" class="f-w-600 f-12 font-light">
                    Página Inicial
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>

var SHA256 = require("crypto-js/sha256");

import { mapState, useStore } from 'vuex';

import { app, auth, provider } from '../firebaseConfig'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, signInWithPopup } from "firebase/auth";

import { insertUser, logSignIn } from '../data/users';


import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ position: 'bottom' });

export default {
  name: 'login',
  data() {
    return {
      entering: false,
      user: null,
      passwordType: 'password',
      email: '',
      password: '',
      registerEmail: '',
      registerPassword: '',
      registerConfirmPassword: '',
      authError: null,
      registerError: null,
      tryingToLogIn: false,
      tryingToRegister: false,
      showMode: 'login',
    };
  },
  computed: {
    // JWT authentication
    loggingIn() {
      //return this.$store.state.authentication.status.loggingIn;
    },
  },
  created() {
    // reset login status for JWT
    //this.$store.dispatch('authentication/logout');
  },
  mounted() {
    auth.onAuthStateChanged(async user => {
      this.user = user
      //store.dispatch("fetchUser", user);
      console.log('onAuthStateChanged', user);

      if (user) {

        //insertUser();
        if (this.entering) {
          await insertUser();
          await logSignIn();
          this.entering = false;
        }

        if (user.emailVerified) {

          this.$store.dispatch('setUser', user)

          //REDIRECT HOME
          this.$router.push(
            this.$route.query.redirectFrom || { name: 'home' }
          )

        } else {

          this.$store.dispatch('setUser', null)


          this.showMode = 'verify'

        }
      }
    });
  },
  methods: {

    sendVerification(user) {
      sendEmailVerification(user);
      toaster.warning(`Foi enviada uma mensagem em seu email para confirmação de seu cadastro!`)
    },
    googleLogin() {

      this.entering = true

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = provider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          console.log('googleLogin result', user, token, credential);
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('googleLogin error', errorCode, errorMessage);
          // ...
        });

    },
    register() {

      this.entering = true


      this.tryingToRegister = true
      this.registerError = null

      if (this.registerPassword === this.registerConfirmPassword) {
        createUserWithEmailAndPassword(auth, this.registerEmail, this.registerPassword)
          .then((userCredential) => {
            //this.$store.dispatch('setUser', userCredential)
            console.log('register userCredential', userCredential);
            /* this.$router.push(
              this.$route.query.redirectFrom || { name: 'home' }
            ) */
            this.user = userCredential.user;
            this.sendVerification(this.user)
          })
          .catch((error) => {
            this.user = null;
            this.registerError = "Não é possível registrar com o email e/ou senha informados.";
            this.tryingToRegister = false
            this.$store.dispatch('setUser', null)
            localStorage.removeItem("user")
          });
      }
      else {
        this.registerError = "Confirmação da senha inválida";
      }

    },
    login() {

      this.entering = true

      this.tryingToLogIn = true
      this.authError = null
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {


          console.log('login userCredential', userCredential);
          /* this.$router.push(
            this.$route.query.redirectFrom || { name: 'home' }
          ) */

          if (userCredential.user) {
            if (userCredential.user.emailVerified) {

              

              this.$store.dispatch('setUser', userCredential.user)

              //REDIRECT HOME
              this.$router.push(
                this.$route.query.redirectFrom || { name: 'home' }
              )

            } else {

              this.$store.dispatch('setUser', null)

              this.showMode = 'verify'

            }
          }

        })
        .catch((error) => {
          this.authError = "Email ou senha inválidos";
          this.tryingToLogIn = false
          this.$store.dispatch('setUser', null)
          localStorage.removeItem("user")
        });
    },
    resetPassword() {
      if (this.email) {
        sendPasswordResetEmail(this.email)
          .then(() => {
            // Email sent.
            toaster.success(`Verifique a sua caixa de email para redefinir a sua senha!`)
          })
          .catch((error) => {
            // console.log('error', error)
            // An error happened.
            toaster.error(`Oops! Não foi possível enviar o email de redefinição de senha. Por favor, tente novamente mais tarde.`)
          })
      } else {
        toaster.error(`Oops! Preencha o seu email de cadastro.`)
      }
    },
    showPassword: function () {
      if (this.passwordType === 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
   
  },
};
</script>



<style>

.login-card{
background: rgb(10,15,20) !important;

}


.login-div {
  position: relative;
}

.login-div::before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: rgba(255,255,255,.03);
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 0;
}

.login-div span {
  z-index: 1;
  position: relative;
}

.login-card .login-main {
    box-shadow: 0 0 37px rgba(8,21,66,.05) !important;
    background-color: rgba(0,0,0,.33) !important;
}

.login-card .login-main .theme-form h4 {
    margin-bottom: 30px !important;
}


</style>