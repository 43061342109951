<template>
  <div class="container-fluid my-10" style="min-height: 85vh;">


    <div class="row justify-content-center">

      <div class="card col-md-10" style="min-height: 50vh;">
        <div class="card-header pb-0">
          <h3 class="text-light">Dashboard</h3>
        </div>

        <div class="card-body">

          <div class="row">
            <div class="col-12">
              <p class="text-light text-center my-10">Você não tem permissão para acessar esta página.</p>
            </div>
          </div>


        </div>


      </div>

    </div>

  </div>




  <Footer />
</template>


<script>
import { mapState } from 'vuex';


import 'vue3-carousel/dist/carousel.css'
import Footer from '../../components/footer.vue';
import { getUser, updateUser } from '../../data/users';
import { app, auth, provider } from '../../firebaseConfig'

import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'bottom' });


export default {

  components: {
    Footer,
  },
  data() {
    return {
      userData: {},
      loaded: false,
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 7.5,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  async mounted() {

    auth.onAuthStateChanged(async user => {

      if (user) {
        this.userData = await getUser();
      }
      else {
        this.$router.replace('/login');
      }

    });



  },
  computed: {

  },
  methods: {

    checkPhone(phone) {
      const regexTelefone = /^\+[0-9\s()-]{10,}$/
      return regexTelefone.test(phone);
    },


    async submitProfile() {

      if (this.userData['displayName'] && this.userData['email'] && (!this.userData['phoneNumber'] || this.checkPhone(this.userData['phoneNumber']))) {
        var result = await updateUser(this.userData)
        if (result > 0) {
          toaster.success(`Dados atualizados com sucesso!`)
        }
        else {
          toaster.error(`Oops! Não foi possível atualizar no momento. Verifique seus dados e tente novamente.`)
        }
      }
      else {
        toaster.warning(`Oops! Preencha os campos corretamente`)
      }

    }


  },
  setup() {
    return {
      modules: [],
    };
  },
};
</script>


<style></style>