<template>
    <Loading v-model:active="loading" :is-full-page="true" background-color="#060606" opacity="1" color="#f00" width="48"
        v-if="$route.path !== '/karaoke'" />

    <router-view />
</template>



<script>
import { mapState, mapGetters } from 'vuex';
import { getSonglist } from './data/songlist';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';


export default {
    name: 'App',
    data() {
        return {
            loaded: false,

        };
    },
    components: {
        Loading
    },
    methods: {
        showLoader(status) {

            console.log('showLoader', status);


            if (status) {
                this.loader = this.$loading.show({ backgroundColor: '#060606', opacity: 1, color: '#f00', width: 48, zIndex: 999999 })
            }
            else {
                this.loader.hide()
            }
        }
    },
    computed: {
        ...mapState({
            loading: (state) => state.loading
        }),
    },
    watch: {
        loading(newValue, oldValue) {
            console.log('watch', newValue, oldValue);
            // Executa quando o valor da propriedade 'count' na store muda

            this.loading = newValue

        }
    },
    async mounted() {

        this.$store.dispatch('setLoading', true)


        // Recuperar usuário JSON do localStorage
        var user = JSON.parse(localStorage.getItem('user'));
        this.$store.dispatch('setUser', user);


        // Recuperar a playlist JSON do localStorage
        var playlist = JSON.parse(localStorage.getItem('playlist'));
        this.$store.dispatch('setPlaylist', playlist);

        // Recuperar a currentSong JSON do localStorage
        var currentSong = JSON.parse(localStorage.getItem('currentSong'));
        this.$store.dispatch('setCurrentSong', { song: currentSong });




        var songlist = await getSonglist()


        if (songlist.songs && songlist.songs.length > 0) {
            songlist.songs.splice(0, 1)
        }

        if (songlist.banners && songlist.banners.length > 0) {
            songlist.banners.splice(0, 1)
        }

        if (songlist.news && songlist.news.length > 0) {
            songlist.news.splice(0, 1)
        }

        if (songlist.selections && songlist.selections.length > 0) {
            songlist.selections.splice(0, 1)
        }

        this.$store.dispatch('setSonglist', songlist)

        console.log('songlist', songlist);

        this.loaded = true;
        this.$store.dispatch('setLoading', false)


    }
}
</script>


<style>
.vl-icon svg path{
  -webkit-transform-origin: 0 0 !important;
  transform-origin: 0 0 !important;
}
</style>